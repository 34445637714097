.vacancy-list {
  h2 {
    font-size: 48px;
    text-transform: uppercase;
  }
  h3 {
    font-size: 36px;
    text-transform: uppercase;
  }
  .controls {
    padding-top: 64px;
  }
  .col-1,.col-2 {
    padding: 50px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #000;
    justify-content: space-between;
    h3 {
      font-weight: 400;
    }
    .item {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #000;
      padding: 16px 0 44px;

      .title {
        min-width: 120px;
      }
      
      ul {
        flex: 1;
        margin: 0;
        list-style-type: none;
        font-size: 18px;
        line-height: 23px;
        li {
          margin-bottom: 16px;
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
  .col-2 {
    background: #000;
    color: #fff;
    .item {
      border-color: #fff;
      ul {
        ul {
          list-style-type: disc;
          padding-left: 30px;
        }
      }
    }
  }
  .hidden {
    color: #000;
    position: relative;
    z-index: -1;
  }
}
