.footer {
  background: #000;
  color: #fff;
  font-size: 18px;
  padding: 16px 0;
  .row {
    justify-content: space-between;
    align-items: center;
    div {
      display: inline-flex;
      align-items: center;
      flex: 1 0 auto;
      &:last-child {
        justify-content: flex-end;
        img {
          margin-left: 20px;
        }
      }
    }
  }
}
